<!--
 * @Descripttion:
 * @version: 1.0
 * @Author: Niklaus
 * @Date: 2021-11-09 14:16:37
-->
<template>
  <wk-header ref="header" :currentIndex="2"></wk-header>
  <el-main class="min-main" style="background:#FAFAFA; padding-top:0">
    <div >
    <el-row >
      <el-col :span="18" :offset="3" style="background-color: #fff;">
        <el-row class="mt30">
          <el-col :span="20" :offset="2">
            <h3 class="mt10 news-title center">{{newsObj.title}}</h3>
            <p class="title-p">
              <span class="inline root-acti mr20">{{newsObj.industryStr}}</span>
              <span class="inline gray mr20">{{newsObj.readNums}}人浏览</span>
              <!-- <span class="inline gray">10分钟之前发布</span> -->
              <span class="inline gray" v-if="newsObj.duration<60">{{newsObj.duration}}分钟之前发布</span>
              <span class="inline gray" v-else-if="newsObj.duration>=60&&newsObj.duration<1400">{{parseInt(newsObj.duration/60)}}小时之前发布</span>
              <span class="inline gray" v-else-if="newsObj.duration>=1400&&newsObj.duration<2880">24小时之前发布</span>
              <span class="inline gray" v-else-if="newsObj.duration>=2880&&newsObj.duration<4320">48小时之前发布</span>
              <span class="inline gray" v-else>{{newsObj.publishTime}}</span>
            </p>

            <section v-html="newsObj.newsContent" class="news-content">
            </section>
          </el-col>
        </el-row>

      </el-col>
    </el-row>
  </div>
  </el-main>
  <wk-footer></wk-footer>
</template>

<script>
import WkHeader  from '@/components/WKHeader'
import WkFooter from '@/components/WKFooter'
import {getAction} from '@/utils/http'

export default {
  name: 'NewsDetail',
  components: {
    WkHeader,
    WkFooter,
  },
  data() {
    return {
      newsObj:{
        mainPic:'',
        title:'',
        newsContent:'',
        publishTime:'',
        readNums:'',
        mainPics:[],
      },
      urls:{
        selectDetail:'/wbpt/news/tbWbptNews/customQueryById',

      }
    }
  },
  beforeMount() {
    // if(this.$ls.get("currentNews")){
    //   this.newsObj = this.$ls.get("currentNews");
    // }
    if(this.$route.query.newsId){
      this.newsObj.id = this.$route.query.newsId;
    }
  },
	mounted(){
		//阅读数加1
		this.addNewsReadnum(this.newsObj.id)
    //查询资讯详情
    this.getNewsDetail();
	},
  methods:{
    //查询资讯详情
    getNewsDetail(){
      let params ={
        id: this.newsObj.id
      }
      getAction(this.urls.selectDetail,params).then(res=>{
        if(res.success){
          this.newsObj = res.result.tbWbptNews;
          this.newsObj.mainPics = res.result.mainPics;
          let industryStr = "";
          if(res.result.types&&res.result.types.length>0){
            res.result.types.forEach(item => {
              industryStr = industryStr + item+"  ";
            });
          }
          this.newsObj.industryStr = industryStr;
        }else{
          // ElMessage.error('获取资讯详情失败！')
          console.log('获取资讯详情失败！');
        }
      }).catch(err=>{
          // ElMessage.error('获取资讯详情出错!')
          console.log(err);
      });
    },
    //阅读数加1
		addNewsReadnum:function(id){
      let userId = '';
      if(this.$ls.get("wikeHome_current_userInfo")){
        userId = this.$ls.get("wikeHome_current_userInfo").id;
      }
      let obj={
        newsId:id,
        userId:userId
      }
			getAction("/wbpt/news/home/updateNewsReadnumWithId",obj).then(res=>{
				console.log(res)
			})
		},
    //下一个方法
  }
}
</script>
<style scoped>
.news-title {
  font-size: 1.8rem;
}
.title-p {
  text-align: center;
  margin-top: 15px;
  padding-bottom: 30px;
  border-bottom: 1px solid #D4D4D4;
}
.news-content {
  width: 80%;
  margin-left: 10%;
}
</style>
